<template>
	<div>
		<div class="main1">
			<!-- 邮箱账号 -->
			<!-- <div class="content">
				<div class="left">
					<div class="title">{{$t('emailaccounts')}}</div>
					<div class="desc">{{$t('emailaccountstitle')}}{{myInfo.email}}{{$t('Lognow')}}</div>
				</div>
				<div class="right">
					<div @click="modifyEmail" class="button">{{$t('revision')}}</div>
				</div>
			</div> -->
			<!-- 手机账号 -->
			<div class="content">
				<div class="left">
					<div class="title">{{$t('telaccounts')}}</div>
					<div class="desc">{{$t('telaccountstitle')}}{{myInfo.mobile}}{{$t('Lognow')}}</div>
				</div>
				<div class="right">
					<div @click="modifyPhone" class="button">{{$t('changes')}}</div>
				</div>
			</div>
			<!-- 登陆密码 -->
			<div class="content">
				<div class="left">
					<div class="title">{{$t('loginpassword')}}</div>
					<div class="desc">{{$t('loginpasswordtitle')}}</div>
				</div>
				<div class="right">
					<div @click="modifyLoginPassword" class="button">{{$t('revision')}}</div>
				</div>
			</div>
			<!-- 支付密码 -->
			<div class="content">
				<div class="left">
					<div class="title">{{$t('paypassword')}}</div>
					<div class="desc">{{$t('paypasswordtitle')}}</div>
				</div>
				<div class="right">
					<div v-if="myInfo.paypwd == 0" @click="setPaymentPassword" class="button">{{$t('setting')}}</div>
					<div v-else @click="modifyPaymentPassword" class="button">{{$t('revision')}}</div>
				</div>
			</div>


		</div>
		<!-- 更改邮箱弹窗 -->
		<PopupCommon ref="popupEmail" customClassName="textarea-modal" title=" " :visible.sync="visible"
			:width="'556px'">
			<div :slot="'title'" class="pop-title">{{$t('changeemail')}}</div>
			<div :slot="'content'" class="pop-content">
				<el-form :model="ruleFormEmail" :rules="rulesEmail" ref="ruleFormEmail" label-width="80px"
					class="demo-ruleForm">
					<el-form-item :label="$t('newemail')" prop="newEmail">
						<el-input v-model="ruleFormEmail.newEmail" :placeholder="$t('emailtitle')"></el-input>
					</el-form-item>
					<el-form-item :label="$t('authcode')" prop="code">
						<el-input class="code" style="width: 200px;" v-model="ruleFormEmail.code"
							:placeholder="$t('authcodetitle')">
							<el-button slot="append" type="primary" :disabled="vCodeLoading">{{$t('haveauthcode')}}</el-button>
						</el-input>
					</el-form-item>
				</el-form>
			</div>
			<div :slot="'footer'" class="pop-footer">
				<div class="cancel" @click="cancelEmail">{{$t('recommend.cancel')}}</div>
				<div class="confirm" @click="confirmEmail">{{$t('recommend.confirm')}}</div>
			</div>
		</PopupCommon>
		<!-- 更改手机账号弹窗 -->
		<PopupCommon ref="popupPhone" customClassName="textarea-modal" title=" " :visible.sync="visible"
			:width="'556px'">
			<div :slot="'title'" class="pop-title">{{$t('changemobile')}}</div>
			<div :slot="'content'" class="pop-content">
				<el-form :model="ruleFormPhone" :rules="rulesPhone" ref="ruleFormPhone" label-width="80px"
					class="demo-ruleForm">
					<el-form-item :label="$t('newmobile')" prop="newPhone">
						<el-input v-model="ruleFormPhone.newPhone" :placeholder="$t('newmobiletitle')"></el-input>
					</el-form-item>
					<el-form-item :label="$t('authcode')" prop="code">
						<el-input class="code" style="width: 200px;" v-model="ruleFormPhone.code"
						:placeholder="$t('authcodetitle')">
							<el-button @click="changeCode" slot="append" type="primary" :disabled="vCodeLoading">{{ vCodeBtnText }}</el-button>
						</el-input>
					</el-form-item>
				</el-form>
			</div>
			<div :slot="'footer'" class="pop-footer">
				<div class="cancel" @click="cancelPhone">{{$t('recommend.cancel')}}</div>
				<div class="confirm" @click="confirmPhone">{{$t('recommend.confirm')}}</div>
			</div>
		</PopupCommon>

		<!-- 更改登录密码弹窗 -->
		<PopupCommon ref="popupLoginPwd" customClassName="textarea-modal" title=" " :visible.sync="visible"
			:width="'556px'">
			<div :slot="'title'" class="pop-title">{{ $t('changeloginpassword') }}</div>
			<div :slot="'content'" class="pop-content">
				<el-form :model="ruleFormLoginPwd" :rules="rulesLoginPwd" ref="ruleForm" label-width="80px"
					class="demo-ruleForm">
					<el-form-item :label="$t('oldpassword')" prop="oldPassword">
						<el-input v-model="ruleFormLoginPwd.oldPassword" :placeholder="$t('oldpasswordtitle')"></el-input>
					</el-form-item>
					<el-form-item  :label="$t('newpassword')" prop="newPassword">
						<el-input v-model="ruleFormLoginPwd.newPassword" :placeholder="$t('newpasswordtitle')"></el-input>
					</el-form-item>
					<el-form-item  :label="$t('subpassword')" prop="confirmPassword">
						<el-input v-model="ruleFormLoginPwd.confirmPassword" :placeholder="$t('newpasswordtitle')"></el-input>
					</el-form-item>
				</el-form>
			</div>
			<div :slot="'footer'" class="pop-footer">
				<div class="cancel" @click="cancelLoginPwd">{{$t('recommend.cancel')}}</div>
				<div class="confirm" @click="confirmLoginPwd">{{$t('recommend.confirm')}}</div>
			</div>
		</PopupCommon>

		<!-- 设置支付密码 -->
		<PopupCommon ref="popupSetPayPwd" customClassName="textarea-modal" title=" " :visible.sync="visible"
			:width="'556px'">
			<div :slot="'title'" class="pop-title">{{$t('setloginpassword')}}</div>
			<div :slot="'content'" class="pop-content">
				<el-form :model="ruleFormSetPayPwd" :rules="rulesSetPayPwd" ref="ruleForm" label-width="120px"
					class="demo-ruleForm-pay">
					<el-form-item :label="$t('enterpaymentpassword')" prop="Password">
						 <PayPasswordInput
						    ref="passwordInputRef"
						    v-model="ruleFormSetPayPwd.Password"
						    has-gap
						    :id="'1'" @getPwd="getPwd"></PayPasswordInput>
					</el-form-item>
					<el-form-item :label="$t('subpaymentpassword')" prop="confirmPassword">
						<!-- <el-input v-model="ruleFormSetPayPwd.confirmPassword" placeholder="确认支付密码"></el-input> -->
						<PayPasswordInput
						   ref="passwordInputRef1"
						   v-model="ruleFormSetPayPwd.confirmPassword"
						   has-gap
						   :id="'2'" @getPwd="getPwdConfirm"></PayPasswordInput>
					</el-form-item>
				</el-form>
			</div>
			<div :slot="'footer'" class="pop-footer">
				<div class="cancel" @click="cancelSetPaymentPwd">{{$t('recommend.cancel')}}</div>
				<div class="confirm" @click="confirmSetPaymentPwd">{{$t('recommend.confirm')}}</div>
			</div>
		</PopupCommon>

		<!-- 修改支付密码弹窗 -->
		<PopupCommon ref="popupModifyPayPwd" customClassName="textarea-modal" title=" " :visible.sync="visible"
			:width="'556px'">
			<div :slot="'title'" class="pop-title">{{$t('setpaypassword')}}</div>:label="$t('nowpaypassword')"
			<div :slot="'content'" class="pop-content">
				<el-form :model="ruleFormModifyPayPwd" :rules="rulesModifyPayPwd" ref="ruleForm" label-width="120px"
					class="demo-ruleForm-pay">
					<el-form-item :label="$t('nowpaypassword')" prop="oldPassword">
						<!-- <el-input v-model="ruleFormModifyPayPwd.oldPassword" placeholder="输入当前支付密码"></el-input> -->
						<PayPasswordInput
						   ref="passwordInputRef2"
						   v-model="ruleFormModifyPayPwd.oldPassword"
						   has-gap
						   :id="'1'" @getPwd="getModifyPwd"></PayPasswordInput>
					</el-form-item>
					<el-form-item :label="$t('newpaypassword')" prop="newPassword">
						<!-- <el-input v-model="ruleFormModifyPayPwd.newPassword" placeholder="新支付密码"></el-input> -->
						<PayPasswordInput
						   ref="passwordInputRef3"
						   v-model="ruleFormModifyPayPwd.newPassword"
						   has-gap
						   :id="'2'" @getPwd="getModifyPwdNew"></PayPasswordInput>
					</el-form-item>
					<el-form-item :label="$t('subpaypassword')" prop="confirmPassword">
						<!-- <el-input v-model="ruleFormModifyPayPwd.confirmPassword" placeholder="确认支付密码"></el-input> -->
						<PayPasswordInput
						   ref="passwordInputRef3"
						   v-model="ruleFormModifyPayPwd.confirmPassword"
						   has-gap
						   :id="'3'" @getPwd="getModifyPwdConfirm"></PayPasswordInput>
					</el-form-item>
				</el-form>
			</div>
			<div :slot="'footer'" class="pop-footer">
				<div class="cancel" @click="cancelModifyPayPwd">{{$t('recommend.cancel')}}</div>
				<div class="confirm" @click="confirmModifyPayPwd">{{$t('recommend.confirm')}}</div>
			</div>
		</PopupCommon>
	</div>

</template>

<script>
	import {
	  updateInfo,
	  updatePassword,
	  setPaypwd,
	  updatePatpwd
	} from '@/api/Mine';
	import {
		sendVCode
	} from "@/utils/common";
	import PopupCommon from "@/components/popup/PopupCommon.vue"
	import PayPasswordInput from "@/components/content/PayPasswordInput.vue"
	export default {
		props: {},
		components: {
			PopupCommon,
			PayPasswordInput
		},
		data() {
			return {
				isPwd: false,
				passwordValue: '',
				password: '',
				visible: false,
				visible1: false,
				// 更改邮箱
				ruleFormEmail: {
					newEmail: '', //新邮箱
					code: '', //验证码
				},
				rulesEmail: {},
				loading: false,
				vCodeLoading: false,
				vCodeCount: 60,
				vCodeBtnText: this.$t("testGetCode"),
				// 更改手机号
				ruleFormPhone: {
					newPhone: '', //新手机号
					code: '', //验证码
				},
				rulesPhone: {},
				// 更改登陆密码
				ruleFormLoginPwd: {
					oldPassword: '', //旧密码
					newPassword: '', //新密码
					confirmPassword: '', //确认密码
				},
				rulesLoginPwd: {},
				// 设置支付密码
				ruleFormSetPayPwd: {
					Password: '', //密码
					confirmPassword: '', //确认密码
				},
				rulesSetPayPwd: {},
				// 修改支付密码
				ruleFormModifyPayPwd: {
					oldPassword: '', //旧密码
					newPassword: '', //新密码
					confirmPassword: '', //确认密码
				},
				rulesModifyPayPwd: {},
				myInfo: {}
			}
		},
		created() {
			this.myInfo = JSON.parse(localStorage.getItem('myInfo'))
		},
		methods: {
			sendVCode,
			changeCode() {
				// console.log(this.ruleForm, 'changeCode')
				this.sendVCode(this.telephoneCode, this.ruleFormPhone.newPhone)
				this.countDown()
			},
			countDown() {
				let inter = setInterval(() => {
					if (this.vCodeCount == 0) {
						clearInterval(inter)
						this.vCodeLoading = false
						this.vCodeCount = 60
						this.vCodeBtnText = this.$t("testGetCode")
					} else {
						this.vCodeLoading = true
						this.vCodeCount--
						this.vCodeBtnText = `${this.vCodeCount}${this.$t("testGetCode")}`
					}
				}, 1000)
			},
			// 修改电话和邮箱
			modifyInfo(type){
				//type==1 修改邮箱 type==2 修改手机号
				let verificationCode
				if(type == 1){
					verificationCode = this.ruleFormEmail.code
				}else if(type == 2){
					verificationCode = this.ruleFormPhone.code
				}
				updateInfo({
					mobile: this.ruleFormPhone.newPhone,//手机号
					email: this.ruleFormEmail.newEmail,//邮箱
					verificationCode: verificationCode,//验证码
					old_mobile: '',//旧手机号
				}).then((res) => {
						console.log(res, '修改电话和邮箱成功');
						if(res.status == 1){
							this.$message({
							          message: res.msg,
							          type: 'success'
							        });
						}else {
							this.$message({
							          message: res.msg,
							          type: 'error'
							        });
						}
						// this.infoObj = res.data
					})
					.catch((err) => {
						console.log(err, '修改电话和邮箱失败');
					});
			},
			// 修改登录密码
			modifyPassword(){
				updatePassword({
					old_password: this.ruleFormLoginPwd.oldPassword,//旧密码
					new_password: this.ruleFormLoginPwd.newPassword,//新密码
					new_password_confirmed: this.ruleFormLoginPwd.confirmPassword,//确认密码
				}).then((res) => {
						console.log(res, '修改登录密码成功');
						if(res.status == 1){
							this.$message({
							          message: res.msg,
							          type: 'success'
							        });
						}else {
							this.$message({
							          message: res.msg,
							          type: 'error'
							        });
						}
						// this.infoObj = res.data
					})
					.catch((err) => {
						console.log(err, '修改登录密码失败');
					});
			},
			// 设置支付密码
			setPaypassword(){
				setPaypwd({
					new_password: this.ruleFormSetPayPwd.Password,//新密码
					new_password_confirmed: this.ruleFormSetPayPwd.confirmPassword,//确认密码
				}).then((res) => {
						console.log(res, '设置支付密码成功');
						if(res.status == 1){
							this.$message({
							          message: res.msg,
							          type: 'success'
							        });
							this.$refs.popupSetPayPwd.close()
						}else {
							this.$message({
							          message: res.msg,
							          type: 'error'
							        });
						}
						// this.infoObj = res.data
					})
					.catch((err) => {
						console.log(err, '设置支付密码失败');
					});
			},
			// 设置支付密码
			getPwd(e){
				console.log(e,'输入支付密码')
				this.ruleFormSetPayPwd.Password = e
			},
			getPwdConfirm(e){
				console.log(e,'确认支付密码')
				this.ruleFormSetPayPwd.confirmPassword = e
			},
			// 修改支付密码
			modifyPatpwd(){
				updatePatpwd({
					old_password: this.ruleFormModifyPayPwd.oldPassword,//原密码
					new_password: this.ruleFormModifyPayPwd.newPassword,//新密码
					new_password_confirmed: this.ruleFormModifyPayPwd.confirmPassword,//确认密码
				}).then((res) => {
						console.log(res, '修改支付密码成功');
						if(res.status == 1){
							this.$message({
							          message: res.msg,
							          type: 'success'
							        });
							this.$refs.popupModifyPayPwd.close()
						}else {
							this.$message({
							          message: res.msg,
							          type: 'error'
							        });
						}
						// this.infoObj = res.data
					})
					.catch((err) => {
						console.log(err, '修改支付密码失败');
					});
			},
			// 修改支付密码
			getModifyPwd(e){
				this.ruleFormModifyPayPwd.oldPassword = e
			},
			getModifyPwdNew(e){
				this.ruleFormModifyPayPwd.newPassword = e
			},
			getModifyPwdConfirm(e){
				this.ruleFormModifyPayPwd.confirmPassword = e
			},
			// // 密码明文切换
			// changePwd(data) {
			// 	this.isPwd = !this.isPwd
			// 	if (data) {
			// 		this.passwordValue = data
			// 	}
			// },
			// onPasswordInputComplete(pwd){
			// 	 console.log('密码输入完成: ', pwd);
			// },
			// 打开修改邮箱弹窗
			modifyEmail() {
				this.$refs.popupEmail.open()
			},
			cancelEmail() {
				this.$refs.popupEmail.close()
			},
			confirmEmail() {
				this.$refs.popupEmail.close()
			},
			// 打开修改手机账号弹窗
			modifyPhone() {
				this.$refs.popupPhone.open()
			},
			cancelPhone() {
				this.$refs.popupPhone.close()
			},
			confirmPhone() {
				this.$refs.popupPhone.close()
			},
			// 打开登录密码弹窗
			modifyLoginPassword() {
				this.$refs.popupLoginPwd.open()
			},
			cancelLoginPwd() {
				this.$refs.popupLoginPwd.close()
			},
			confirmLoginPwd() {
				this.$refs.popupLoginPwd.close()
			},
			// 打开设置支付密码弹窗
			setPaymentPassword() {
				this.$refs.popupSetPayPwd.open()
			},
			cancelSetPaymentPwd() {
				this.$refs.popupSetPayPwd.close()
				this.$refs.passwordInputRef.emptyList()
				this.ruleFormSetPayPwd.confirmPassword = ''
				this.ruleFormSetPayPwd.Password = ''
			},
			confirmSetPaymentPwd() {
				this.$refs.popupSetPayPwd.close()
				console.log(this.ruleFormSetPayPwd,'this.ruleFormSetPayPwd')
			},
			// 打开修改支付密码弹窗
			modifyPaymentPassword() {
				this.$refs.popupModifyPayPwd.open()
			},
			cancelModifyPayPwd() {
				this.$refs.popupModifyPayPwd.close()
			},
			confirmModifyPayPwd() {
				this.$refs.popupModifyPayPwd.close()
			}
		}
	}
</script>

<style lang="less" scoped>
	.dPwd {
		position: relative;

		.pwdIcon {
			width: 20px;
			height: 20px;
			display: block;
			position: absolute;
			right: 20px;
			top: 10px;
		}
	}

	.main1 {
		width: 100%;
		height: 650px;
		background: @recommendMusicianListBGColor;
		border-radius: 6px;
		backdrop-filter: blur(50px);
		margin-top: 16px;
		display: flex;
		flex-direction: column;
		// align-items: center;
		// justify-content: center;
		color: @musicLibraryFilterMenuNavTextColor;

		.content {
			// width: 100%;
			height: 76px;
			display: flex;
			justify-content: space-between;
			align-items: center;
			margin: 0 33px;

			.left {
				display: flex;

				.title {
					color: #999999;
					font-size: 14px;
				}

				.desc {
					margin-left: 16px;
					font-size: 14px;
				}
			}

			.right {
				display: flex;

				.button {
					width: 88px;
					height: 36px;
					background: #3370FF;
					border-radius: 4px;
					display: flex;
					align-items: center;
					justify-content: center;
					font-size: 14px;
					font-weight: 600;
					color: #FFFFFF;
					cursor:pointer;
				}
			}
		}
	}

	// 更改邮箱弹窗 更改手机账号弹窗
	.pop-title {
		height: 68px;
		display: flex;
		align-items: center;
		border-bottom: 1px solid @demoitemtabsBGColor;
		padding: 0 25px;
		color: @recommendMusicianListTextColor;
	}

	.pop-content {
		// max-height: 86px;
		padding: 24px;
		border-bottom: 1px solid @demoitemtabsBGColor;
		padding: 25px;
		color: @recommendMusicianListTextColor;
		line-height: 22px;

		.demo-ruleForm {
			::v-deep .el-input__inner {
				width: 390px;
				background: @recommendMusicianListBGColor;
				border: none;
				color: @recommendMusicianListTextColor;
			}

			::v-deep .el-form-item__label {
				color: @recommendMusicianListTextColor;
				
			}

			.code {
				::v-deep .el-input__inner {
					width: 302px;
					background: @recommendMusicianListBGColor;
					border: none;
				}

				::v-deep .el-form-item__label {
					color: @recommendMusicianListTextColor;
				}

				::v-deep .el-button--primary {
					width: 86px;
					height: 38px;
					background: #3370FF;
					border-radius: 4px;
					border: none;
					display: flex;
					justify-content: center;
					align-items: center;
					font-size: 14px;
					color: #FFFFFF;
				}

				::v-deep .el-input-group__append,
				.el-input-group__prepend {
					background: rgba(0, 0, 0, 0);
					border: none;
				}
			}
		}

		.demo-ruleForm-pay {
			::v-deep .el-input__inner {
				width: 320px;
				background: @recommendMusicianListBGColor;
				border: none;
				
			}

			::v-deep .el-form-item__label {
				color: @recommendMusicianListTextColor;
			}

			.code {
				::v-deep .el-input__inner {
					width: 222px;
					background: @recommendMusicianListBGColor;
					border: none;
				}

				::v-deep .el-form-item__label {
					color: @recommendMusicianListTextColor;
				}

				::v-deep .el-button--primary {
					width: 86px;
					height: 38px;
					background: #3370FF;
					border-radius: 4px;
					border: none;
					display: flex;
					justify-content: center;
					align-items: center;
					font-size: 14px;
					color: #FFFFFF;
				}

				::v-deep .el-input-group__append,
				.el-input-group__prepend {
					background: rgba(0, 0, 0, 0);
					border: none;
				}
			}
		}

	}

	.pop-footer {
		display: flex;
		align-items: center;
		height: 68px;
		justify-content: center;

		.cancel {
			width: 102px;
			height: 36px;
			border-radius: 4px;
			border: 1px solid @popupCancelBorderColor;
			display: flex;
			align-items: center;
			justify-content: center;
			font-size: 14px;
			color: @popupCancelTextColor;
			margin-right: 44px;
			background: @popupCancelBgColor;
			cursor:pointer;
		}

		.confirm {
			width: 102px;
			height: 36px;
			background: #3278FF;
			border-radius: 4px;
			display: flex;
			align-items: center;
			justify-content: center;
			font-size: 14px;
			color: #FFFFFF;
			cursor:pointer;
		}
	}

	// 更改手机账号弹窗
	.pop-phone-title {
		height: 68px;
		display: flex;
		align-items: center;
		border-bottom: 1px solid @demoitemtabsBGColor;
		padding: 0 25px;
		color: @recommendMusicianListTextColor;
	}

	.pop-phone-content {
		max-height: 86px;
		padding: 24px;
		border-bottom: 1px solid @demoitemtabsBGColor;
		padding: 25px;
		color: @recommendMusicianListTextColor;
		line-height: 22px;
	}

	.pop-phone-footer {
		display: flex;
		align-items: center;
		height: 68px;
		justify-content: center;

		.cancel {
			width: 102px;
			height: 36px;
			border-radius: 4px;
			border: 1px solid @popupCancelBorderColor;
			display: flex;
			align-items: center;
			justify-content: center;
			font-size: 14px;
			color: @popupCancelTextColor;
			margin-right: 44px;
			background: @popupCancelBgColor;
			cursor:pointer;
		}

		.confirm {
			width: 102px;
			height: 36px;
			background: #3278FF;
			border-radius: 4px;
			display: flex;
			align-items: center;
			justify-content: center;
			font-size: 14px;
			color: #FFFFFF;
			cursor:pointer;
		}
	}

	// 设置支付密码弹窗
	// .pop-pay-title{
	// 	height: 68px;
	// 	display: flex;
	// 	align-items: center;
	// 	border-bottom: 1px solid @demoitemtabsBGColor;
	// 	padding: 0 25px;
	// 	color: @recommendMusicianListTextColor;
	// }

	// .pop-pay-content {
	// 	max-height: 86px;
	// 	padding: 24px;
	// 	border-bottom: 1px solid @demoitemtabsBGColor;
	// 	padding: 25px;
	// 	color: @recommendMusicianListTextColor;
	// 	line-height: 22px;
	// }

	// .pop-pay-footer {
	// 	display: flex;
	// 	align-items: center;
	// 	height: 68px;
	// 	justify-content: center;

	// 	.cancel {
	// 		width: 102px;
	// 		height: 36px;
	// 		border-radius: 4px;
	// 		border: 1px solid @popupCancelBorderColor;
	// 		display: flex;
	// 		align-items: center;
	// 		justify-content: center;
	// 		font-size: 14px;
	// 		color: @popupCancelTextColor;
	// 		margin-right: 44px;
	// 		background: @popupCancelBgColor;
	// 	}

	// 	.confirm {
	// 		width: 102px;
	// 		height: 36px;
	// 		background: #3278FF;
	// 		border-radius: 4px;
	// 		display: flex;
	// 		align-items: center;
	// 		justify-content: center;
	// 		font-size: 14px;
	// 		color: #FFFFFF;
	// 	}
</style>
