<template>
 <div>
	 <div class="spend-pay-1">
	     <!-- <div class="spend-pay-2">支付密码</div> -->
	     <!-- 支付框 -->
	     <div class='am_payPwd' :id="`ids_${id}`">
			 <div class="am_payPwd_wrap">
				 <input type="password"
				  autocomplete="new-password"  
				  maxlength="1"
				  @input="changeInput"
				  @click="changePwd"
				  v-model="pwdList[i]"
				  @keyup="keyUp($event)"
				  @keydown="oldPwdList = pwdList.length"
				  class="shortInput"
				  v-for="(v, i) in 6" :key="i">
				  <!-- <div style="width: 1px;height: 20px;background: red;">1</div> -->
			 </div>
	        
	     </div>   
	     <!--后一篇文章说明 autocomplete="new-password"作用-->
	     <!-- 结束 -->
	 </div>
	 <!-- <div>
	     <div class="spend-pay-3">请输入6位数字支付密码</div>
	     <div class="spend-pay-4">忘记密码？</div>
	 </div>
	 <div class="spend-pay-5">
	     <el-button class="spend-pay-button" type="primary" round >
	     去支付
	     </el-button>
	 </div> -->
	 
 </div>
</template>

<script>
export default {
  data () {
    return {
      //支付框
      pwdList: [],
      oldPwdList: [],
      isDelete: false,
      ipt: '',
      //结束
    };
  },
  //支付框
  props: {
    id: String, // 当一个页面有多个密码输入框时，用id来区分
    default: '1'
  },
  //结束
  mounted () {　
    //支付框　
    this.ipt = document.querySelectorAll(`#ids_${this.id} .shortInput`)
  },
  methods: {
    //   支付框
    keyUp (ev) {
      let index = this.pwdList.length
      if (!index) return
      if (ev.keyCode === 8) {
        this.isDelete = true
        if (this.oldPwdList === this.pwdList.length) {
          if (index === this.pwdList.length) {
            this.pwdList.pop()
          }
          index--
        } else {
          index > 0 && index--
        }
        this.ipt[index].focus()
      } else if (this.isDelete && index === this.pwdList.length && /^\d$/.test(ev.key)) {
        this.isDelete = false
        this.pwdList.pop()
        this.pwdList.push(ev.key)
        this.ipt[this.pwdList.length] && this.ipt[this.pwdList.length].focus()
      }
      this.$emit('getPwd', this.pwdList.join(''))
    },
    //支付框
    changePwd () {
      let index = this.pwdList.length
      index === 6 && index--
      this.ipt[index].focus()
    },
    //支付框
    changeInput (e) {
		// console.log(e,this.pwdList,'this.pwdList')
      let index = this.pwdList.length
      let val = this.pwdList[index - 1]
    　　   if (!/[0-9]/.test(val)) {　
        this.pwdList.pop() 　　　　  　　　　 
        return 　　　 　　
      }
      if (!val) {
        this.pwdList.pop()
        index--
        if (index > 0) this.ipt[index - 1].focus()
      } else {
        if (index < 6) this.ipt[index].focus()
      }
    },
	// 清空数组
	emptyList(){
		this.pwdList = []
	}
  }
}

</script>

<style lang="less">
	
  /* 支付框样式 */
    .am_payPwd {
        display: flex;
		align-items: center;
		line-height: 42px;
        // margin-left: 70px;   
        width: 282px;
        height: 42px;
		border: 1px solid #cccccc;
		// background: #F4F4F4;
		background: @personInfoFormColor;
		border-radius: 4px;
    
    }
	.am_payPwd_wrap{
		display: flex;
		align-items: center;
		line-height: 42px;
		// margin-left: 70px;   
		// width: 42px;
		height: 42px;
		// background: #F4F4F4;
		background: @personInfoFormColor;
	}
    /* 支付框样式 */
    .shortInput {
        // background: #cccccc;
		border: 0;
        border-right: 1px solid #cccccc;
        text-align: center;
        font-size: 40px;
        float: left;
        width: 42px;
        height: 42px;
        color: @recommendMusicianListTextColor;
        outline: #ff0067;
		background: rgba(0,0,0,0);
		position: relative;
		&::after{
			display:inline-block;
			position: absolute;
			right: 100px;
			top: 100px;
			content: " 1";
			width: 10px;
			height: 20px;
			background: red;
		}
    }
	
	.shortInput:focus{
		width: 42px;
		height: 42px;
		background: rgba(34,36,38,0.05);
		box-shadow: 0px 0px 6px 0px rgba(34,36,38,0.26);
		border-radius: 4px;
		border: 1px solid #D4D4D4;
	}
    /* 支付框样式 */
    .shortInput:not(:last-child) {
        // margin-right: 10px;
    }
	.shortInput:last-child {
	    border: 0;
	}
    // .spend-pay-button{
    //     margin: 20px auto 20px 70px;
    // }
       .spend-pay-1{
        // margin-top: 4%;
        position: relative;
    }
    .spend-pay-2{
        font-size: 14px;
        font-weight:550;
        display: inline-block;
        position: absolute;
        top:0;
    }
    .spend-pay-3{
        margin-left: 70px;
        display: inline-block;
        font-size: 10px;
        color: #cccccc; 
    }
    .spend-pay-4{
        margin-left: 85px;
        display: inline-block;
        font-size: 10px;
        font-weight: 600;
    }
      /* 去支付按钮样式 */
    // .spend-pay-5 .el-button--medium {
    //     padding: 10px 35px;
    //     border-radius: 17px;
    // }

</style>